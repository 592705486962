import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';

const AuthRoutes = () => {
    const authToken = { loggedIn: localStorage.getItem('token') }
    return authToken && authToken.loggedIn;
}


export const ProtectedRoutes = () => {
    const isAuth = AuthRoutes();
    return isAuth ? <Outlet /> :  <Navigate replace to="auth/login" />;
}
