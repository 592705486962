import metricssApiClient, { errorMessage } from "../utils/axios-utils";

const prefix = "/feature-flags";

export const fetchFeatureFlags = async () => {
  try {
    const res = await metricssApiClient().get(`${prefix}`);
    return res?.data;
  } catch (err) {
    errorMessage(err);
  }
};

export const storeFeatureFlags = async (data) => {
  try {
    const res = await metricssApiClient().post(`${prefix}`, data);
    return res?.data;
  } catch (err) {
    errorMessage(err);
  }
};

export const updateFeatureFlag = async ({ uuid, data }) => {
  try {
    const res = await metricssApiClient().patch(`${prefix}/${uuid}`, { ...data });
    return res?.data;
  } catch (err) {
    errorMessage(err);
  }
};

export const deleteFeatureFlag = async (uuid) => {
  try {
    const res = await metricssApiClient().delete(`${prefix}/${uuid}`);
    return res?.data;
  } catch (err) {
    errorMessage(err);
  }
};
