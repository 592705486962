import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';

function simulateNetworkRequest() {
  return new Promise((resolve) => setTimeout(resolve, 2000));
}

function LoadingButton(props) {
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      simulateNetworkRequest().then(() => {
        setLoading(false);
      });
    }
  }, [isLoading]);

  const handleClick = () => {
    setLoading(true)
    props.onClick()
  };

  return (
    <Button
      variant={props.variant ?? "primary" }
      className={props.className}
      disabled={isLoading ?? props.disabled}
      onClick={!isLoading ? handleClick : null}
      as={props.as}
      to={props.to}
    >
      <span>{isLoading ? 'Loading… ' : props.isInitial ?? 'Click to load '}</span>
      <span>{ props.icon }</span>
      
    </Button>
  );
}

export default LoadingButton;