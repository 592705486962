import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  fetchEnterprise,
  updateEnterprise,
  createEnterprise,
  updateEnterpriseAvatar,
  removeEnterpriseAvatar,
  fetchEnterpriseMembers
} from '../api/enterprise';

export default function useFetchEnterprisePaginate(
  page,
  paginate,
  searchQuery,
  selectedCategory
) {
  const fetchAdmin = useQuery({
    queryKey: ['companies', page, searchQuery, selectedCategory.value],
    queryFn: async () =>
      await fetchEnterprise(page, paginate, searchQuery, selectedCategory),
    keepPreviousData: true,
  });

  return fetchAdmin;
}

export function useFetchEnterprise() {
  const fetchAdmin = useQuery({
    queryKey: ['companies'],
    queryFn: async () => await fetchEnterprise(),
    keepPreviousData: true,
  });

  return fetchAdmin;
}

export function useFetchEnterpriseMembers($companyId) {
  const data = useQuery({
    queryKey: ['companyId', $companyId],
    queryFn: async () => await fetchEnterpriseMembers($companyId),
    keepPreviousData: true,
  });

  return data;
}

export const useUpdateEnterprise = () => {
  const client = useQueryClient();

  return useMutation(updateEnterprise, {
    onSuccess: (data) => {
      client.invalidateQueries('companies');
    },
  });
};

export const useUpdateEnterpriseAvatar = () => {
  const client = useQueryClient();

  return useMutation(updateEnterpriseAvatar, {
    onSuccess: (data) => {
      client.invalidateQueries('companies');
    },
  });
};

export const useRemoveEnterpriseAvatar = () => {
  const client = useQueryClient();

  return useMutation(removeEnterpriseAvatar, {
    onSuccess: (data) => {
      client.invalidateQueries('companies');
    },
  });
};

export const useCreateEnterprise = () => {
  const client = useQueryClient();

  return useMutation(createEnterprise, {
    onSuccess: (data) => {
      client.invalidateQueries('companies');
    },
  });
};
