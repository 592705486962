import metricssApiClient, { errorMessage } from '../utils/axios-utils';

const url = `/roles`;

const fetchRoles = async (page, paginate) => {
  try {
    const roles = await metricssApiClient().get(
      `${url}?${paginate ? `page=${page}` : 'generic=true'}`
    );
    return roles?.data;
  } catch (error) {
    errorMessage(error);
  }
};

const createRoles = async (data) => {
  try {
    const newRole = await metricssApiClient().post(`${url}`, data);
    return newRole?.data;
  } catch (error) {
    errorMessage(error);
  }
};

const deleteRole = async (roleId) => {
  const deletedRole = await metricssApiClient().delete(`${url}/${roleId}`);
  return deletedRole?.data;
};

const updateEnterpriseRole = async ({ name, data }) => {
  try {
    const res = await metricssApiClient().patch(`${url}/${name}`, { ...data });
    return res?.data;
  } catch (err) {
    errorMessage(err);
  }
};

export { fetchRoles, createRoles, deleteRole, updateEnterpriseRole };
