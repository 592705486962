import { Table } from 'reactstrap';
import Pagination from 'react-bootstrap/Pagination';
import { useState } from 'react';
import MultiSelect from 'multiselect-react-dropdown';
import { useUpdateEnterprise } from '../../hooks/useFetchEnterprise';
import { Button, Form, Modal, Spinner, Stack } from 'react-bootstrap';
import { useDeleteRole, useUpdateEnterpriseRole } from '../../hooks/useRoles';
import Modals from '../Modals';
import { useGetFeatureFlags } from '../../hooks/useFeatureFlags';

const RolesTable = ({
  isFetchingRoles,
  rolesError,
  roles,
  silentRefetchRoles,
  setPageNumber,
  pageNumber,
}) => {
  const { mutateAsync: handleDeleteRole } = useDeleteRole();

  const currentPage = roles?.data?.current_page;
  const last_page = roles?.data?.last_page;
  const pageListNumber = Array(last_page)
    .fill()
    .map((_, idx) => idx + 1);

  const PaginationList = (
    <Pagination className="justify-content-center">
      <Pagination.Prev
        disabled={pageNumber === 1}
        onClick={() => setPageNumber((page) => page - 1)}
      />
      {pageListNumber.map((uiPageNumber) => (
        <Pagination.Item
          onClick={() => setPageNumber(uiPageNumber)}
          key={uiPageNumber}
          active={uiPageNumber === currentPage}
        >
          {uiPageNumber}
        </Pagination.Item>
      ))}
      <Pagination.Next
        disabled={pageNumber === last_page}
        onClick={() => setPageNumber((page) => page + 1)}
      />
    </Pagination>
  );

  //delete Modal state controller
  const [modalShow, setModalShow] = useState(false);
  const [modalValue, setModalValue] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedEnterprise, setSelectedEnterprise] = useState(null);

  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedUuids, setSelectedUuids] = useState([]);

  const { data: featuresData, isLoading: isFetchingFeatureFlags } =
    useGetFeatureFlags();

  const {
    mutateAsync: saveEnterpriseFeatures,
    isLoading: isUpdatingEnterprise,
    isSuccess,
    error,
    reset,
  } = useUpdateEnterpriseRole();

  const handleToggleModal = (visibility) =>
    setShowModal(visibility === 'show' ? true : false);

  const handleShowModal = (activeEnterprise) => {
    setSelectedEnterprise(activeEnterprise);
    handleToggleModal('show');

    console.log(activeEnterprise);
    setSelectedFeatures(activeEnterprise.features);

    const uuids = activeEnterprise.features.map((feature) => feature.uuid);
    setSelectedUuids(uuids);
  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedFeatures(selectedList);

    const uuids = selectedList.map((item) => item.uuid);
    setSelectedUuids(uuids);
  };

  const onSelect = (selectedList, selectedItem) => {
    setSelectedFeatures(selectedList);

    const uuids = selectedList.map((item) => item.uuid);
    setSelectedUuids(uuids);
  };

  const confirmDeleteRole = (e, roleId) => {
    setModalValue(roleId);

    if (modalShow === true) {
      setModalShow((prep) => false);
      if (e?.target.innerText === 'Delete') {
        handleDeleteRole(modalValue);
      }
    } else {
      setModalShow((prep) => true);
    }
  };

  const handleSaveEnterpriseFeatures = async () => {
    if (!selectedUuids?.length) {
      window.alert('Please make at least one selection');
      return;
    }

    const payload = {
      features: selectedUuids,
    };

    await saveEnterpriseFeatures({
      name: selectedEnterprise?.name,
      data: payload,
    });
  };

  return (
    <>
      <Table className="no-wrap mt-3 align-middle" responsive bordered hover>
        <thead>
          <tr>
            <th>Display Name</th>
            <th>Company</th>
            <th>Description</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {isFetchingRoles ? (
            <tr>
              <td>Loading...</td>
            </tr>
          ) : (
            roles?.data?.data?.map((tdata, index) => (
              <tr key={index} className="border-top">
                <td>{tdata.display_name}</td>
                <td>{tdata.company?.name || ''}</td>
                <td>{tdata.description || ''}</td>
                <td>
                  <div style={{ display: 'flex', gap: '1rem' }}>
                    <Button
                      onClick={(e) => confirmDeleteRole(e, tdata.name)}
                      variant="outline-danger"
                    >
                      {
                        <span>
                          <i className="bi bi-trash"></i>
                        </span>
                      }
                    </Button>
                    <Button
                      onClick={(e) => handleShowModal(tdata)}
                      variant="info"
                    >
                      {
                        <span>
                          <i className="bi bi-pen"></i>
                        </span>
                      }
                    </Button>
                  </div>
                </td>
              </tr>
            ))
          )}
          {rolesError && (
            <tr> {'An error has occurred: ' + rolesError.message} </tr>
          )}
        </tbody>
      </Table>

      <Modal
        show={showModal}
        onHide={() => {
          handleToggleModal('hide');
          reset();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Update {selectedEnterprise?.display_name || ''} settings
          </Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Features</Form.Label>
              <MultiSelect
                options={featuresData?.data || []}
                selectedValues={selectedFeatures}
                onSelect={onSelect}
                onRemove={onRemove}
                displayValue="name"
                loading={isFetchingFeatureFlags}
                loadingMessage={'Loading features...'}
                avoidHighlightFirstOption
                showArrow
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleToggleModal('hide');
                reset();
              }}
            >
              Close
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={isUpdatingEnterprise}
              onClick={handleSaveEnterpriseFeatures}
            >
              <Stack
                direction="horizontal"
                gap={3}
                className="justify-content-center"
              >
                {isUpdatingEnterprise && (
                  <Spinner animation="grow" className="mr-3" />
                )}
                <span>Update</span>
              </Stack>
            </Button>
          </Modal.Footer>
          {isSuccess && (
            <small
              style={{
                color: 'green',
                paddingLeft: '12px',
                paddingBottom: '10px',
              }}
            >
              Role has been updated
            </small>
          )}
          {error && (
            <small
              style={{
                color: 'red',
                paddingLeft: '12px',
                paddingBottom: '10px',
              }}
            >
              {error.message}
            </small>
          )}
        </Form>
      </Modal>

      <Modals
        show={modalShow}
        onHide={(e) => confirmDeleteRole(e)}
        submitbutton={'Delete'}
        type={'button'}
        title={'Delete Role'}
        btnvariant={'danger'}
      >
        Please confirm you want to delete this role.
      </Modals>
      {PaginationList}
    </>
  );
};

export default RolesTable;
