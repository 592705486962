import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createRoles, deleteRole, updateEnterpriseRole, fetchRoles } from '../api/roles';

export default function useFetchRolesPaginate(page, paginate) {
  const fetchAdmin = useQuery({
    queryKey: ['roles', page],
    queryFn: async () => await fetchRoles(page, paginate),
    keepPreviousData: true,
  });

  return fetchAdmin;
}

export function useFetchRoles() {
  const fetchAdmin = useQuery({
    queryKey: ['roles'],
    queryFn: async () => await fetchRoles(),
    keepPreviousData: true,
  });

  return fetchAdmin;
}


export const useCreateRole = () => {
  const client = useQueryClient();

  return useMutation(createRoles, {
    onSuccess: (data) => {
      client.invalidateQueries('roles');
    },
  });
};

export function useDeleteRole(id) {
    const queryClient = useQueryClient()

    const _deleteRole = useMutation(
        (id) => {
            return deleteRole(id);
        }, {
        onSuccess: (data) => {
            queryClient.invalidateQueries('roles')
        }
    });

  return _deleteRole;
}


export const useUpdateEnterpriseRole = () => {
  const client = useQueryClient();

  return useMutation(updateEnterpriseRole, {
    onSuccess: (data) => {
      client.invalidateQueries("roles");
    },
  });
};
