import React from 'react'
import { Button, Modal } from 'react-bootstrap'

export default function Modals(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
            {
                props.submitbutton 
                &&                 
                <Modal.Footer>
                    <Button 
                        type={props.type ?? 'button'}
                        className={props.className}
                        onClick={props.onHide}
                        variant={props.btnvariant}
                    >
                        <span>{props.icon} </span>
                        <span>{props.submitbutton}</span>
                    </Button>
                </Modal.Footer>
            }
        </Modal>
    )
}
