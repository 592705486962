import React from 'react';
import MultiSelect from 'multiselect-react-dropdown';
import LoadingButton from '../components/ui/LoadingBtn';
import Modals from '../components/Modals';
import { Button, Form, Spinner } from 'react-bootstrap';
import useFetchRolesPaginate, { useCreateRole } from '../hooks/useRoles';
import { useGetFeatureFlags } from '../hooks/useFeatureFlags';
import { useFetchEnterprise } from '../hooks/useFetchEnterprise';
import RolesTable from '../components/dashboard/RolesTable';

const Roles = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);
  const {
    mutateAsync: createRole,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useCreateRole();
  const { data: featuresData, isLoading: isFetchingFeatureFlags } =
    useGetFeatureFlags();

  const {
    isLoading: isFetchingRoles,
    error: rolesError,
    data: roles,
    refetch: silentRefetchRoles,
  } = useFetchRolesPaginate(pageNumber, true);

  const { data: enterprises, isLoading: isFetchingEnterprise } = useFetchEnterprise();

  const [selectedFeatures, setSelectedFeatures] = React.useState([]);
  const [selectedUuids, setSelectedUuids] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState([]);

  const onSelectCompany = (selectedList, selectedItem) => {
    setSelectedCompany(selectedList);
  };

  const onSelect = (selectedList, selectedItem) => {
    setSelectedFeatures(selectedList);

    const uuids = selectedList.map((item) => item.uuid);
    setSelectedUuids(uuids);
  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedFeatures(selectedList);

    const uuids = selectedList.map((item) => item.uuid);
    setSelectedUuids(uuids);
  };

  const handleCreateEnterpriseAccount = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const name = formData.get('name').trim();
    const display_name = formData.get('display_name').trim();
    const description = formData.get('description')?.trim();

    const payload = {
      name,
      display_name,
      description,
      company_id: selectedCompany[0]?.id || null,
      features: selectedUuids,
    };

    const response = await createRole(payload);
    response?.success && silentRefetchRoles();
    response?.success && setModalShow(false);
  };

  return (
    <div className="admin_container">
      <div className="add_admin">
        <LoadingButton
          isInitial={`Create Role`}
          className={'justify-content-end'}
          onClick={() => {
            setModalShow((prep) => true)
            setSelectedCompany([])
          }}
        />
      </div>
      <Modals
        show={modalShow}
        title={'Create Role'}
        onHide={() => setModalShow((prep) => false)}
      >
        <Form onSubmit={handleCreateEnterpriseAccount}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="scandium-tester"
              name="name"
              autoFocus
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Display Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Scandium Tester"
              name="display_name"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="Description"
              name="description"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Company</Form.Label>
            <MultiSelect
              options={enterprises?.data || []}
              selectedValues={selectedCompany}
              onSelect={onSelectCompany}
              displayValue="name"
              loading={isFetchingEnterprise}
              loadingMessage={'Loading Companies...'}
              avoidHighlightFirstOption
              showArrow
              singleSelect
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Features</Form.Label>
            <MultiSelect
              options={featuresData?.data || []}
              selectedValues={selectedFeatures}
              onSelect={onSelect}
              onRemove={onRemove}
              displayValue="name"
              loading={isFetchingFeatureFlags}
              loadingMessage={'Loading features...'}
              avoidHighlightFirstOption
              showArrow
            />
          </Form.Group>
          <div className="add_admin_modal">
            <small style={{ color: isSuccess ? 'green' : 'red' }}>
              {isError
                ? error?.message
                : isSuccess
                ? 'Company created successfully'
                : null}
            </small>
            <Button
              disabled={isLoading}
              variant={
                isSuccess
                  ? 'outline-success'
                  : isError
                  ? 'outline-danger'
                  : 'outline-primary'
              }
              type="submit"
            >
              {isLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span> wait...</span>
                </>
              ) : (
                'Submit'
              )}
            </Button>
          </div>
        </Form>
      </Modals>
      <RolesTable
        isFetchingRoles={isFetchingRoles}
        rolesErrorError={rolesError}
        roles={roles}
        silentRefetchRoles={silentRefetchRoles}
        setPageNumber={setPageNumber}
        pageNumber={pageNumber}
      />
    </div>
  );
};

export default Roles;
