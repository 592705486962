import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { ProtectedRoutes } from "../views/auth/ProtectedRoutes.js";
import Roles from "../views/Roles.js";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/

const Dashboard = lazy(() => import("../views/Dashboard.js"));
const FeatureFlags = lazy(() => import("../views/FeatureFlags.js"));
const About = lazy(() => import("../views/About.js"));
const Users = lazy(() => import("../views/Users.js"));
const Login = lazy(() => import("../views/auth/Login.js"));
const Admin = lazy(() => import("../views/Admin.js"));
const Enterprise = lazy(() => import("../views/Enterprise.js"));
const EnterpriseMembers = lazy(() => import("../views/ui/EnterpriseMembers.js"));
const UserTestCase = lazy(() => import("../views/ui/UserTestCase.js"));
const UserProject = lazy(() => import("../views/ui/UserProject.js"));
const UserFeatures = lazy(() => import("../views/ui/UserFeatures.js"));
const Cards = lazy(() => import("../views/ui/Cards"));
const Profile = lazy(() => import("../views/ui/Profile.js"));

/*****Routes******/

const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      {
        path: "/",
        element: <ProtectedRoutes />,
        children: [
          { path: "/", element: <Dashboard /> },
          { path: "/dashboard", exact: true, element: <Navigate to="/" /> },
          { path: "/feature-flags", exact: true, element: <FeatureFlags /> },
          { path: "/about", exact: true, element: <About /> },
          { path: "/users", exact: true, element: <Users /> },
          { path: "/admins", exact: true, element: <Admin /> },
          { path: "/enterprise", exact: true, element: <Enterprise /> },
          { path: "/enterprise/:enterpriseId/members", exact: true, element: <EnterpriseMembers /> },
          { path: "/roles", exact: true, element: <Roles /> },
          { path: "/profile", exact: true, element: <Profile /> },
          { path: "/users/:userId", element: <Cards /> },
          { path: "/users/:userId/test-cases", element: <UserTestCase /> },
          { path: "/users/:userId/projects", element: <UserProject /> },
          { path: "/users/:userId/features", element: <UserFeatures /> },
        ],
      },
    ],
  },
  { path: "auth/login", element: <Login /> },
];

export default ThemeRoutes;
