import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteFeatureFlag, fetchFeatureFlags, storeFeatureFlags, updateFeatureFlag } from "../api/featureFlags.api";

export const useGetFeatureFlags = () => {
  return useQuery({
    queryKey: ["featureFlags"],
    queryFn: fetchFeatureFlags,
    keepPreviousData: true,
  });
};

export const useCreateFeatureFlag = () => {
  const client = useQueryClient();

  return useMutation(storeFeatureFlags, {
    onSuccess: (data) => {
      client.invalidateQueries("featureFlags");
    },
  });
};

export const useUpdateFeatureFlag = () => {
  const client = useQueryClient();

  return useMutation(updateFeatureFlag, {
    onSuccess: (data) => {
      client.invalidateQueries("featureFlags");
    },
  });
};

export const useDeleteFeatureFlag = () => {
  const client = useQueryClient();

  return useMutation(deleteFeatureFlag, {
    onSuccess: (data) => {
      client.invalidateQueries("featureFlags");
    },
  });
};
